import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import { responseErrorMessage } from "@/modules/loadable";
import PropTypes from "prop-types";
import {
    notifyAboutStatus,
    notifyError,
    notifySuccess,
} from "@/modules/loadable/useMutationLoadableWithNotification.hook";
import { useCustomModal } from "@/modules/modal";
import { t } from "@/translations";
import { tap } from "lodash/fp";
import React, { useContext } from "react";
import uuid from "uuid/v4";
import { useDic } from "../Dic/useDic.hook";
import { useCurrentHandler } from "../hooks/useCurrentHandler.hook";
import { UserAbortError } from "../Mappers/ConflictModal";
import { TableLocalFiltered } from "../TableLocalFiltered";
import { ApprovalWorkflowsEnabledContext } from "./ApprovalWorkflowsEnabledProvider";

export const WF_ACTION_TYPES = {
    WORKFLOW_INITIATED: "WORKFLOW_INITIATED",
    WORKFLOW_ALREADY_RUNNING: "WORKFLOW_ALREADY_RUNNING",
    WORKFLOW_NOT_FEASIBLE: "WORKFLOW_NOT_FEASIBLE",
};

const WorkflowAlreadyRunningModal = ({
    modalProviderProps,
    workflowResponse: { actionType, wfName, wfId, waitingFor } = {},
}) => {
    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("workflow-running-modal.title")}
                />
                <UnityLayout.Content padding={[true, true]}>
                    <Text>
                        {t("workflow-running-modal.description", {
                            action: wfName,
                        })}
                    </Text>
                    <Gap size="small" />
                    <Text>{t("workflow-running-modal.table.description")}</Text>
                    <Gap size="small" />
                    <TableLocalFiltered
                        columns={[
                            {
                                type: fieldTypes.TEXT,
                                label: "Name",
                                name: "name",
                            },
                            {
                                type: fieldTypes.TEXT,
                                label: "Email",
                                name: "email",
                            },
                            {
                                type: fieldTypes.TEXT,
                                label: "Username",
                                name: "username",
                            },
                        ]}
                        dataSource={waitingFor.map(user => ({
                            ...user,
                            uuid: uuid(),
                        }))}
                        pagination={false}
                        rowKey="uuid"
                        customHeight={{ allowShrink: false, height: 300 }}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: modalProviderProps.onClose,
                                    type: "tertiary",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

export const WorkflowApprovalModal = ({
    modalProviderProps,
    withApprovalWorkflowReason,
    onSubmit,
    onCancel,
}) => {
    const { Form, formId, handleSubmit } = Forms.useForm({
        onSubmit: async ({ values }) => {
            return onSubmit(values);
        },
        initialValues: undefined,
    });
    const onClose = () => {
        onCancel?.();
        modalProviderProps.onClose();
    };
    const actionButtons = [
        {
            label: t("workflow-approval-modal.submit"),
            formId,
            type: "primary",
            htmlType: "submit",
        },
        {
            label: t("general.cancel"),
            onClick: onClose,
            type: "tertiary",
        },
    ];

    return (
        <Modal {...modalProviderProps} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("workflow-approval-modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text dataTest="workflow-approval-description">
                        {t(
                            withApprovalWorkflowReason
                                ? "workflow-approval-modal.description"
                                : "workflow-approval-modal.rationale-description",
                        )}
                    </Text>
                    <Gap size="small" />
                    {withApprovalWorkflowReason && (
                        <Form disableBanner onSubmit={handleSubmit}>
                            <Forms.Fields.TextArea
                                name="rationale"
                                label=""
                                rows="7"
                                validator={Forms.pmValidators.isRequired}
                            />
                        </Form>
                    )}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={<ButtonMenu buttons={actionButtons} />}
                />
            </UnityLayout>
        </Modal>
    );
};

export const WorkflowApprovalPendingModal = ({
    modalProviderProps,
    workflowResponse: { actionType, wfName, wfId, waitingFor } = {}, // TODO
}) => {
    const actionButtons = [
        {
            label: t("general.close"),
            onClick: modalProviderProps.onClose,
            type: "primary",
        },
    ];

    return (
        <Modal {...modalProviderProps}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("workflow-approval-pending-modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        {t("general.workflow-approval-pending.description", {
                            entity: t("general.workflow"),
                        })}
                    </Text>
                    <Gap size="small" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={<ButtonMenu buttons={actionButtons} />}
                />
            </UnityLayout>
        </Modal>
    );
};

export const needsWorkflowApproval = axiosResponse =>
    axiosResponse.status === 202;

const workflowFeedback = (
    axiosResponse,
    { messageService, customModal, trackWorkflow },
) => {
    if (!needsWorkflowApproval(axiosResponse)) return false;

    const { actionType, wfName } = axiosResponse.data;
    trackWorkflow?.({ actionType, wfName });

    switch (actionType) {
        case WF_ACTION_TYPES.WORKFLOW_INITIATED:
            messageService.info({
                content: `The selected action needs to be approved using the '${wfName}' workflow. Please wait for the workflow to finish.`,
            });
            return true;
        case WF_ACTION_TYPES.WORKFLOW_ALREADY_RUNNING:
            customModal.show(
                <WorkflowAlreadyRunningModal
                    workflowResponse={axiosResponse.data}
                />,
            );
            return true;
        case WF_ACTION_TYPES.WORKFLOW_NOT_FEASIBLE:
            messageService.error({
                content: `The selected action needs to be approved using the '${wfName}' workflow, but workflow is not feasible. Please contact a platform administrator.`,
            });
            return true;
        default:
            console.error(`Unknown workflow actionType: ${actionType}`);
            messageService.info({
                content: `Needs workflow approval: ${actionType}`,
            });
            return true;
    }
};

export const withWorkflowFeedback = async (
    { messageService, customModal, trackWorkflow },
    createAxiosPromise,
    success = "general.saved",
    error = responseErrorMessage,
) => {
    try {
        const result = await createAxiosPromise();
        const isWorkflowResponse = workflowFeedback(result, {
            messageService,
            customModal,
            trackWorkflow,
        });

        if (!isWorkflowResponse)
            notifySuccess({ messageService, success, result });

        return result;
    } catch (e) {
        notifyError({ messageService, error, e });
    }
};

export const useWithWorkflowFeedback = ({ success, error } = {}) => {
    const { messageService } = useDic();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);

    return useCurrentHandler(async createAxiosPromise =>
        withWorkflowFeedback(
            { messageService, customModal, trackWorkflow },
            createAxiosPromise,
            success,
            error,
        ),
    );
};

const withWorkflowWithReason = async (
    { messageService, customModal, trackWorkflow },
    createAxiosPromise,
    withApprovalWorkflowReason,
    success,
    error,
) => {
    return new Promise((resolve, reject) => {
        customModal.show(
            <WorkflowApprovalModal
                onSubmit={values => {
                    return withWorkflowFeedback(
                        { messageService, customModal, trackWorkflow },
                        () =>
                            createAxiosPromise({
                                approvalWorkflowReason: values.rationale,
                            }).then(tap(customModal.hide)),
                        success,
                        error,
                    )
                        .then(resolve)
                        .catch(reject);
                }}
                onCancel={() => {
                    reject(new UserAbortError());
                    customModal.hide();
                }}
                withApprovalWorkflowReason={withApprovalWorkflowReason}
            />,
        );
    });
};

export const useWithWorkflowWithReasonMaybe = ({
    withApprovalWorkflowReason = true,
    noWorkflowSuccess,
    noWorkflowError,
} = {}) => {
    const approvalWorkflowEnabled = useContext(
        ApprovalWorkflowsEnabledContext,
    ).EVENT_ORCHESTRATION_APPROVAL;

    const { messageService } = useDic();
    const customModal = useCustomModal();
    const { trackWorkflow } = useContext(TrackingContext);

    return useCurrentHandler(createAxiosPromise =>
        approvalWorkflowEnabled
            ? withWorkflowWithReason(
                  { messageService, customModal, trackWorkflow },
                  createAxiosPromise,
                  withApprovalWorkflowReason,
                  noWorkflowSuccess,
                  noWorkflowError,
              )
            : notifyAboutStatus(
                  messageService,
                  createAxiosPromise,
                  noWorkflowSuccess,
                  noWorkflowError,
              )({ reason: undefined }),
    );
};

WorkflowApprovalModal.propTypes = {
    modalProviderProps: PropTypes.object.isRequired,
    withApprovalWorkflowReason: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};

WorkflowApprovalPendingModal.propTypes = {
    modalProviderProps: PropTypes.object.isRequired,
    workflowResponse: PropTypes.object,
};

WorkflowAlreadyRunningModal.propTypes = {
    modalProviderProps: PropTypes.object.isRequired,
    workflowResponse: PropTypes.object,
};
