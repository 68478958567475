import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useCurrentRef } from "@/components/hooks/useCurrentRef.hook";
import { getPermissionsAssetResourceByType } from "@/components/Permissions/UsersAssets/helpers";
import { splitUserIdsToArray } from "@/components/Permissions/utils";
import { hasValue, LoadingLoadable } from "@/modules/loadable";
import {
    HasErrorLoadable,
    HasValueLoadable,
} from "@/modules/loadable/useQueryLoadable.hook";
import { SecurityContext } from "@/security/authorization";
import { useCheckFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import {
    includesPermission,
    ISV_CONNECTION_EDIT_PERMISSION,
} from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { first, setWith } from "lodash/fp";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useRoute } from "react-router5";
import { useDic } from "../components/Dic/useDic.hook";

export const RouteDataContext = React.createContext({});

const isDefaultParam = value => value === "-1" || value === -1;

const ACTIONS = {
    FECH_START: "FECH_START",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_ERROR: "FETCH_ERROR",
    SET_CURRENT_ROUTE: "SET_CURRENT_ROUTE",
};

const initialState = {
    resources: {},
    currentRoute: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.FECH_START: {
            return setWith(
                Object,
                [
                    "resources",
                    action.meta.resourceName,
                    action.meta.paramName,
                    `${action.meta.value}`,
                ],
                LoadingLoadable(),
                state,
            );
        }
        case ACTIONS.FETCH_SUCCESS: {
            return setWith(
                Object,
                [
                    "resources",
                    action.meta.resourceName,
                    action.meta.paramName,
                    `${action.meta.value}`,
                ],
                HasValueLoadable(action.payload),
                state,
            );
        }
        case ACTIONS.FETCH_ERROR: {
            return setWith(
                Object,
                [
                    "resources",
                    action.meta.resourceName,
                    action.meta.paramName,
                    `${action.meta.value}`,
                ],
                HasErrorLoadable(action.error),
                state,
            );
        }
        case ACTIONS.SET_CURRENT_ROUTE: {
            return { ...state, currentRoute: action.payload };
        }
        default:
            return state;
    }
};

export const fetchers = {
    accountId: {
        account: {
            fetcher: ({ axiosService }, accountId) =>
                axiosService.get(`/api/projects/${accountId}`).then(getData),
            // Endpoint is not cached because BE sets default accountId by it's calling https://pricefx.atlassian.net/browse/PFIM-5958
            isCached: false,
        },
        accountInstances: {
            fetcher: ({ axiosService }, accountId) =>
                axiosService
                    .get(`/api/projects/${accountId}/instances`)
                    .then(getData),
        },
        accountPartitions: {
            fetcher: ({ axiosService }, accountId) =>
                axiosService
                    .get(`/api/projects/${accountId}/partition-assets`)
                    .then(getData),
        },
        accountIsvConnections: {
            fetcher: ({ isvConnectionService }, accountId) =>
                isvConnectionService.listConnections(accountId),
            permission: ISV_CONNECTION_EDIT_PERMISSION,
            featureFlag: "isv-connection",
        },
    },
    instanceId: {
        instance: {
            fetcher: ({ axiosService }, id) =>
                axiosService.get(`/api/instances/${id}`).then(getData),
        },
    },
    partitionId: {
        partitionAssets: {
            fetcher: ({ axiosService, routeInfo }, id) =>
                axiosService
                    .get(
                        `/api/projects/${routeInfo.route.params.accountId}/partition-assets/${id}`,
                    )
                    .then(getData),
        },
    },
    isvConnectionId: {
        connection: {
            fetcher: ({ isvConnectionService }, id) =>
                isvConnectionService.getConnection(id),
        },
    },
    userIds: {
        users: {
            fetcher: (
                { axiosService, permissionsAssetsService, routeInfo },
                userIds,
            ) => {
                const userIdsArr = splitUserIdsToArray(userIds);
                const firstUser = first(userIdsArr);
                return userIdsArr.length > 1
                    ? // Next Feature = load more info about multiple users
                      Promise.resolve(userIdsArr)
                    : routeInfo.route.params.accountId
                    ? permissionsAssetsService
                          .fetchParentEntityName({
                              accountId: routeInfo.route.params.accountId,
                              entityType: "USER",
                              entityId: firstUser,
                          })
                          .then(getData)
                    : axiosService
                          .get(`/api/admin/users/${firstUser}`)
                          .then(getData)
                          .then(user => user.fullName);
            },
        },
    },
    assetId: {
        asset: {
            isCached: false,
            fetcher: ({ permissionsAssetsService, routeInfo }) =>
                getPermissionsAssetResourceByType(
                    permissionsAssetsService,
                    routeInfo.route.params,
                ),
        },
    },
};

export const getResource = (resourceName, paramName, paramValue, resources) =>
    resources?.[resourceName]?.[paramName]?.[paramValue] ?? LoadingLoadable();

const routeIdHasChanged = (paramName, route, previousRoute) =>
    route?.params?.[paramName] !== previousRoute?.params?.[paramName];

export const RouteDataContextProvider = ({ children }) => {
    const { axiosService, isvConnectionService, permissionsAssetsService } =
        useDic();
    const routeInfo = useRoute();
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);
    stateRef.current = state;

    const isEnabledFeature = useCheckFeatureFlag();
    const securityContextValue = useContext(SecurityContext);
    const securityContextValueRef = useCurrentRef(securityContextValue);

    const fetchParamData = useCurrentHandler(
        ({ resourceName, paramName, value, attempt = 0 }) => {
            const fetchAction = fetchers[paramName][resourceName]?.fetcher;
            if (!fetchAction) {
                console.log(
                    `%c[RouteDataContextProvider] Cannot fetch data for route param ${paramName}=${value}`,
                );
                return;
            }
            if (!resourceName || !paramName || !value) {
                console.warn(
                    `%c[RouteDataContextProvider] Missing resourceName, paramName or value`,
                    { resourceName, paramName, value },
                );
                return;
            }

            dispatch({
                type: ACTIONS.FETCH_START,
                meta: { paramName, value, resourceName },
            });
            fetchAction(
                {
                    axiosService,
                    isvConnectionService,
                    permissionsAssetsService,
                    routeInfo,
                },
                value,
                // route.params,
            )
                .then(data =>
                    dispatch({
                        type: ACTIONS.FETCH_SUCCESS,
                        meta: { paramName, value, resourceName },
                        payload: data,
                    }),
                )
                .catch(error =>
                    dispatch({
                        type: ACTIONS.FETCH_ERROR,
                        meta: { paramName, value, resourceName },
                        error,
                    }),
                );
        },
    );

    const onRouteChange = useCallback(
        (routeInfo, forceReload = false) => {
            const { route, previousRoute } = routeInfo;
            Object.entries(route.params)
                .filter(([, value]) => {
                    return value && !isDefaultParam(value);
                })
                .forEach(([paramName, value]) => {
                    Object.keys(fetchers[paramName] ?? {})
                        .filter(resourceName => {
                            const permission =
                                fetchers[paramName][resourceName]?.permission;
                            if (permission) {
                                return includesPermission(
                                    securityContextValueRef.current,
                                    [permission],
                                );
                            }
                            return true;
                        })
                        .filter(resourceName => {
                            const featureFlag =
                                fetchers[paramName][resourceName]?.featureFlag;
                            if (featureFlag) {
                                return isEnabledFeature(featureFlag);
                            }
                            return true;
                        })
                        .forEach(resourceName => {
                            const isCached =
                                fetchers[paramName][resourceName]?.isCached ??
                                true;
                            if (
                                (!isCached &&
                                    routeIdHasChanged(
                                        paramName,
                                        route,
                                        previousRoute,
                                    )) ||
                                !hasValue(
                                    getResource(
                                        resourceName,
                                        paramName,
                                        value,
                                        stateRef.current.resources,
                                    ),
                                ) ||
                                forceReload
                            ) {
                                fetchParamData({
                                    resourceName,
                                    paramName,
                                    value,
                                });
                            }
                        });
                });

            dispatch({ type: ACTIONS.SET_CURRENT_ROUTE, payload: route });
        },
        [fetchParamData],
    );

    const refetchRouteData = useCurrentHandler(() =>
        onRouteChange(routeInfo, true),
    );

    useEffect(() => {
        onRouteChange(routeInfo);
    }, [routeInfo, dispatch, onRouteChange]);

    const context = useMemo(
        () => ({
            currentRoute: state.currentRoute,
            resources: state.resources,
            refetchRouteData,
            fetchResource: fetchParamData,
        }),
        [state.currentRoute, state.resources, refetchRouteData, fetchParamData],
    );

    return (
        <RouteDataContext.Provider value={context}>
            {children}
        </RouteDataContext.Provider>
    );
};

RouteDataContextProvider.propTypes = {
    children: PropTypes.node,
};
