import { useRouteParams } from "@/modules/router";
import { mapValues } from "lodash";
import { useMemo } from "react";

export const addTypeAndSort = byType =>
    mapValues(byType, (permissions, type) =>
        permissions
            .map(p => ({ ...p, type }))
            .sort((a, b) => a.name.localeCompare(b.name)),
    );

const userIdsSeparator = "-";

export const joinUserIdsToUrlParam = userIds => userIds.join(userIdsSeparator);
export const splitUserIdsToArray = userIds =>
    userIds.split(userIdsSeparator).map(Number);

export const useRouteUserIds = () => {
    const routeParams = useRouteParams();

    return useMemo(() => {
        try {
            console.log("routeParams", routeParams);
            return routeParams.userIds.split(userIdsSeparator).map(Number);
        } catch (e) {
            return [];
        }
    }, [routeParams.userIds]);
};
export const useRouteAccountIds = () => {
    const routeParams = useRouteParams();

    return useMemo(() => {
        try {
            return splitUserIdsToArray(routeParams.accountIds);
        } catch (e) {
            return [];
        }
    }, [routeParams.accountIds]);
};
