import { hasError, hasValue } from "@/modules/loadable";
import { UserContext } from "@/security/UserContext";
import { locale } from "@/translations/unityTranslations";
import { createHashedEmail } from "@/utils/appcues";
import {
    entries,
    every,
    fromPairs,
    get,
    getOr,
    intersection,
    isEqual,
    isFinite,
    keys,
    map,
    pick,
    pickBy,
    pipe,
    reject,
    some,
} from "lodash/fp";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useRoute } from "react-router5";
import { useDic } from "../components/Dic/useDic.hook";
import { fetchers, RouteDataContext } from "./RouteDataContextProvider";
import { zipObject } from "lodash";

export const MIXPANEL_EVENTS = {
    PAGE_VIEW: "Page View",
    BUTTON_CLICK: "Button Click",
    MENU_CLICK: "Menu Click",
    // EMAIL_SEND: "Email send",
    STEP_ENGINE: "Step Engine",
    DATA_UPLOAD: "Data Upload",
    WORKFLOW: "Workflow",
};

const notifyMissingContext = () =>
    console.error("Missing TrackingContext implementation!");
// would be better to throw an error, but tests are failing then
export const TrackingContext = React.createContext({
    trackButtonClick: notifyMissingContext,
    trackStepEngine: notifyMissingContext,
    handleMenuClick: notifyMissingContext,
    trackWorkflow: notifyMissingContext,
    trackDataUpload: notifyMissingContext,
});

const isDefaultParam = value => ["-1", -1].includes(value);

const getDepsResources = ({ deps = {}, resources, defaultValue }) =>
    pipe(
        entries,
        map(([paramName, paramValue]) => ({
            paramName,
            paramValue,
            resource: getOr(
                defaultValue,
                [paramToResourceName[paramName], paramName, paramValue],
                resources,
            ),
        })),
    )(deps);

const depsLoaded = (deps, resources) =>
    pipe(
        getDepsResources,
        every(({ resource }) => hasValue(resource) || hasError(resource)), // swallow errors? https://pricefx.atlassian.net/browse/PFIM-7454
    )({ deps, resources, defaultValue: {} });

const paramNameToPath = {
    accountId: "name",
    partitionId: "serialNumber",
    instanceId: "instanceName",
};

const paramToAttribute = {
    accountId: "account",
    partitionId: "partition",
    instanceId: "integration",
};

const paramToResourceName = {
    accountId: "account",
    partitionId: "partitionAssets",
    instanceId: "instance",
};

const dependencyToAttribute =
    resources =>
    ([param, id]) => {
        const attribute = paramToAttribute[param];
        const value = pipe(
            get([paramToResourceName[param], param, id]),
            resource =>
                hasValue(resource)
                    ? getOr(
                          "UNKNOWN",
                          ["contents", paramNameToPath[param]],
                          resource,
                      )
                    : getOr("ERROR", ["contents", "message"], resource),
        )(resources);
        return [attribute, value];
    };

const resolveDeps = (deps, resources) => {
    if (!depsLoaded(deps, resources)) throw new Error("Cannot resolve deps");

    return pipe(
        entries,
        map(dependencyToAttribute(resources)),
        fromPairs,
    )(deps);
};

const fetchableParams = Object.keys(fetchers);
const pickFetchableDeps = pipe(
    pick(
        intersection(
            ["accountId", "partitionId", "instanceId"],
            fetchableParams,
        ),
    ),
    pickBy((value, key) => isFinite(parseInt(value, 10))),
);

const rejectDefaultParams = deps => {
    return pipe(entries, reject(isDefaultParam), fromPairs)(deps);
};
const hasDefaultParams = deps => {
    return Object.values(deps).some(isDefaultParam);
};

const getPageType = route => route.name.split(".").slice(0, 4).join(".");

const getTemplateNameObject = route => {
    const templateName = route.params?.packageName || route.params?.uniqueName;

    if (!templateName) return {};

    const [namespace, template] = templateName.split(":");

    // whatever will come
    if (!template) return { "Template name": namespace };

    const name = namespace.split(".").pop();
    const identifier = [name, template].join(":");

    return { "Template name": identifier };
};

const dropEvent = (event, resources) => {
    const has401 = pipe(
        getDepsResources,
        some(
            ({ resource }) =>
                hasError(resource) &&
                resource?.contents?.response?.status === 401,
        ),
    )({
        deps: event.deps,
        resources,
        defaultValue: {},
    });
    if (has401) {
        console.warn(
            `Dropping event ${event.name} because of 401 error in deps`,
            event,
        );
    }
    return has401;
};

class AsyncParamsManager {
    mixpanelService;
    queue = [];
    resources = {};
    debug = false;

    constructor({ mixpanelService }) {
        this.mixpanelService = mixpanelService;
        this.debug = mixpanelService.config.debug;
    }

    _debugLog(...args) {
        if (!this.debug) return;
        console.log("%c[AsyncParamsManager]", "color:blue;", ...args);
    }

    _processQueue() {
        this._debugLog(
            `_processQueue start (${this.queue.length} unprocessed)`,
            {
                "this.queue": this.queue,
                "this.resources": this.resources,
            },
        );
        let canProceed = !!this.queue.length;

        while (canProceed) {
            const [event, ...rest] = this.queue;
            // if (someDepsErrored(event.deps, this.resources)) {
            //     console.warn( `Refetching ${event.name} because of error in deps`, { event, resources: { ...this.resources } }, );
            //     this._refetchErrored(event);
            //     canProceed = false;
            // } else
            if (depsLoaded(event.deps, this.resources)) {
                if (!dropEvent(event, this.resources))
                    this._processEvent(event);

                this.queue = rest;
                canProceed = !!rest.length;
            } else {
                canProceed = false;
            }
        }
        this._debugLog(`_processQueue end (${this.queue.length} unprocessed)`, {
            "this.queue": this.queue,
            "this.resources": this.resources,
        });
    }

    _processEvent(event) {
        const resolvedAttributes = resolveDeps(event.deps, this.resources);
        const attributes = {
            ...resolvedAttributes,
            ...event.attributes,
        };
        this._debugLog("_processEvent", {
            event,
            resolvedAttributes,
            attributes,
        });

        this.mixpanelService.track(event.name, attributes);
    }

    handleResourcesChange(resources) {
        this._debugLog("handleResourcesChange", { resources });
        this.resources = resources;
        this._processQueue();
    }

    trackWithDeps({ name, deps, attributes }) {
        this._debugLog("trackWithDeps", { name, deps, attributes });
        this.queue.push({
            name,
            deps,
            attributes,
        });
        this._processQueue();
    }

    handleRouteChange(route) {
        const deps = pickFetchableDeps(route.params);
        const hasDefaults = hasDefaultParams(deps);

        this._debugLog("handleRouteChange", {
            route,
            fetchableParams,
            deps,
            hasDefaults,
        });
        // TODO: redirects (forwardTo + list (defaultPage?))
        if (hasDefaults) return; // Do not track temp locations, TODO: compare with location default params?

        if (!this.mixpanelService.get_property("$user_id")) return; // Do not track unidentified users

        this.trackWithDeps({
            name: MIXPANEL_EVENTS.PAGE_VIEW,
            deps,
            attributes: {
                pageType: getPageType(route),
                URL: route.path,
                ...getTemplateNameObject(route),
            },
        });
    }

    trackButtonClick({ name, clusterUpgradeType, isMajor }, route) {
        const deps = pipe(pickFetchableDeps, rejectDefaultParams)(route.params);
        this._debugLog("trackButtonClick", {
            route,
            deps,
        });
        this.trackWithDeps({
            name: MIXPANEL_EVENTS.BUTTON_CLICK,
            deps,
            attributes: {
                button_name: name?.toString(),
                pageType: getPageType(route),
                ...(clusterUpgradeType ? { clusterUpgradeType, isMajor } : {}),
                ...getTemplateNameObject(route),
            },
        });
    }

    handleMenuClick({ location }, route) {
        const deps = pipe(pickFetchableDeps, rejectDefaultParams)(route.params);
        this._debugLog("trackButtonClick", {
            route,
            deps,
        });
        this.trackWithDeps({
            name: MIXPANEL_EVENTS.MENU_CLICK,
            deps,
            attributes: { routeName: location?.routeName },
        });
    }

    trackWorkflow(attributes, route) {
        const deps = pipe(pickFetchableDeps, rejectDefaultParams)(route.params);
        this._debugLog("trackWorkflow", {
            route,
            deps,
        });
        this.trackWithDeps({
            name: MIXPANEL_EVENTS.WORKFLOW,
            deps,
            attributes,
        });
    }
    trackDataUpload(attributes, route) {
        const deps = pipe(pickFetchableDeps, rejectDefaultParams)(route.params);
        this._debugLog("trackDataUpload", {
            route,
            deps,
        });
        this.trackWithDeps({
            name: MIXPANEL_EVENTS.DATA_UPLOAD,
            deps,
            attributes,
        });
    }

    trackStepEngine(allDeps, attributes, route) {
        const deps = pipe(pickFetchableDeps, rejectDefaultParams)(allDeps);
        const { packageName, result, ...restAttributes } = attributes;
        this._debugLog("trackStepEngine", {
            route,
            deps,
            allDeps,
        });
        this.trackWithDeps({
            name: MIXPANEL_EVENTS.STEP_ENGINE,
            deps,
            attributes: {
                accName: packageName, // Sales Insights
                accResult: result, // success, failed - TODO!!!
                ...restAttributes,
                ...getTemplateNameObject(route),
            },
        });
    }

    // TODO: should be resolved first?
    handleUserInfo({ userInfo }) {
        const USER_ID = createHashedEmail(userInfo.user.username);
        this.mixpanelService.identify(USER_ID);

        const Locale = locale;
        const ReleaseVersion = process.env.VERSION;

        // FROM IMPLEMENTATION NOTES
        const userProfile = {
            $email: USER_ID,
            $first_name: userInfo.user.firstName,
            "Created Date": userInfo.user.createdAt,
            Language: "en",
            Locale,
            "Release Version": ReleaseVersion,
            "User Groups": userInfo.user.groups
                .map(({ label }) => label)
                .join(","),
            // "Business Roles": "<concatenated business roles>",
            // Environment: "<determined environment (prod/dev/qa)>",
            // "Application Type": "<sfdc/standalone/c4c/...>",
            "Tracking Revision": 1,
        };
        const registeredAttributes = {
            Locale,
            ReleaseVersion,
            LoginType: userInfo.loginMethod,
        };

        this._debugLog("handleUserInfo", {
            userInfo,
            USER_ID,
            userProfile,
            registeredAttributes,
        });

        this.mixpanelService.people.set(userProfile);
        this.mixpanelService.register(registeredAttributes);
    }
}

export const TrackingContextProvider = ({ children }) => {
    const { resources } = useContext(RouteDataContext);
    const { basicInfo } = useContext(UserContext);
    const {
        mixpanelService,
        locations: { authRedirLocation, authVerifyLocation, ssoLoginLocation },
    } = useDic();
    const { route } = useRoute();
    const asyncParamsManagerRef = useRef(
        new AsyncParamsManager({ mixpanelService }),
    );
    const routeRef = useRef(route);
    routeRef.current = route;

    const forbiddenRoutesToTrack = [
        authVerifyLocation.routeName,
        authRedirLocation.routeName,
        ssoLoginLocation.routeName,
    ];

    useEffect(() => {
        if (basicInfo) asyncParamsManagerRef.current.handleUserInfo(basicInfo);
    }, [basicInfo]);

    useEffect(() => {
        if (forbiddenRoutesToTrack.includes(route.name)) return;
        asyncParamsManagerRef.current.handleRouteChange(route);
    }, [route]);

    useEffect(() => {
        asyncParamsManagerRef.current.handleResourcesChange(resources);
    }, [resources]);

    const trackButtonClick = useCallback(
        data =>
            asyncParamsManagerRef.current.trackButtonClick(
                data,
                routeRef.current,
            ),
        [],
    );
    const handleMenuClick = useCallback(
        data =>
            asyncParamsManagerRef.current.handleMenuClick(
                data,
                routeRef.current,
            ),
        [],
    );
    const trackWorkflow = useCallback(
        data =>
            asyncParamsManagerRef.current.trackWorkflow(data, routeRef.current),
        [],
    );
    const trackDataUpload = useCallback(
        data =>
            asyncParamsManagerRef.current.trackDataUpload(
                data,
                routeRef.current,
            ),
        [],
    );
    const trackStepEngine = useCallback(
        (deps, attributes) =>
            asyncParamsManagerRef.current.trackStepEngine(
                deps,
                attributes,
                routeRef.current,
            ),
        [],
    );

    const context = useMemo(
        () => ({
            trackButtonClick,
            trackStepEngine,
            handleMenuClick,
            trackWorkflow,
            trackDataUpload,
        }),
        [
            trackButtonClick,
            trackStepEngine,
            handleMenuClick,
            trackWorkflow,
            trackDataUpload,
        ],
    );

    return (
        <TrackingContext.Provider value={context}>
            {children}
        </TrackingContext.Provider>
    );
};

TrackingContextProvider.propTypes = {
    children: PropTypes.node,
};
