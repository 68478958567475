import { assetType } from "@/constants/assets.constants";
import { waitForValue } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const getPermissionsAssetResourceByType = (
    permissionsAssetsService,
    params,
) => {
    switch (params?.assetType) {
        case assetType.partition:
        case assetType.integration:
            if (!params?.assetId && !params?.accountId) {
                return waitForValue();
            } else {
                return permissionsAssetsService
                    .fetchParentEntityName({
                        accountId: params?.accountId,
                        entityType: `${params?.assetType}`.toUpperCase(),
                        entityId: params?.assetId,
                    })
                    .then(getData);
            }
        default:
            return waitForValue();
    }
};
